<template>
	<Toast />
	<Utils ref='Utils' />
	<EditWord @reflushProduts="reflushProduts" @initialProduct='initialProduct' ref='editWord' :product_copy="product"
		:showDialog='productDialog' :disabled_or_copy='disabled_or' v-if='productDialog' />
	<div class="p-grid crud-demo card" style="margin-bottom: 10px;">
		<div class="p-col-12">
			<!-- <div> -->
			<Toolbar class="p-mb-4">
				<template v-slot:left>
					<Button label="新增" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" title='新增单词' />
					<Button label="删除" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
						:disabled="!selectedProducts || !selectedProducts.length" title='批量删除勾选单词' />
				</template>

				<template v-slot:right>
<!-- 					<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="导入" chooseLabel="导入"
						class="p-mr-2 p-d-inline-block" /> -->
					<Button label="导出" icon="pi pi-upload" class="p-button-help p-mr-2" @click="exportCSV($event)" title='导出单词到本地' />
					<Button label="保存" icon="pi pi-cloud-upload" class="p-d-inline-block p-mr-2" @click="uploadWords" title='批量保存勾选单词' />
					<Button label="返回" icon="pi pi-upload" class="p-button-danger" @click="goPageBack" title="返回来源页" />
				</template>
			</Toolbar>
			<!-- 筛选框 -->
			<DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id" :paginator="true"
				:rows="25"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5,10,25,50]"
				currentPageReportTemplate="展示 {first} 至 {last} 共 {totalRecords} 条单词短语" responsiveLayout="scroll"
				v-model:filters="filters1" filterDisplay="menu" :loading="processing" :filters="filters1"
				:globalFilterFields="['or','country.name','representative.name','balance','status']">

				<template #header>
					<pageOperator ref='pageOperate' :operate_list_copy='words_list_status' :this_operate_copy='this_status'
					 @startSearch='getPersonalWord' @initPage='initPage' @initFilters1='initFilters1' />
				</template>

				<template #empty>
					没有找到符合条件的词条
				</template>
				<template #loading>
					加载词条中,请稍后...
				</template>
				<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

				<!-- 原文 -->
				<Column field="or" filterField="or" header="原文" style="min-width:14rem">
					<template #body="{data}">
						<span class="p-column-title">OriginalText</span>
						{{data.or}}
					</template>
					<template #filter="{filterModel}">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter"
							placeholder="Search by name" />
					</template>
				</Column>

				<Column field="wl" header="翻译" filterField="sl.w" style="min-width:14rem">
					<template #body="{data}">
						<span class="p-column-title">Translation</span>
						<Dropdown v-if='data.tl.length>1' v-model="data.sl" :options="data.tl" optionLabel="w"
							placeholder="翻译" />
						<span v-if='data.tl.length==1' class="image-text">{{data.tl[0].w}}</span>
					</template>
					<template #filter="{filterModel}">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter"
							placeholder="Search by name" />
					</template>
				</Column>

				<!-- 					<Column header="引用" field="sl.ref" dataType="numeric" :sortable="true"
						:filterMenuStyle="{'width':'10rem'}" style="min-width:8rem">
						<template #body="{data}">
							<span class="p-column-title">Balance</span>
							{{data.sl.ref}}
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" />
						</template>
					</Column>

					<Column header="SCI词频" field="sl.scip" dataType="numeric" :sortable="true"
						:filterMenuStyle="{'width':'12rem'}" style="min-width:10rem">
						<template #body="{data}">
							<span class="p-column-title">Balance</span>
							{{data.sl.scip}}
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" />
						</template>
					</Column> -->

				<Column header="学科"  filterField="sl.sl_mjid_from.fr"
					:showFilterMatchModes="false" :filterMenuStyle="{'width':'12rem'}" style="min-width:10rem">
					<template #body="{data}">
						<span class="p-column-title">Major</span>
						<span class="image-text">{{data.sl.sl_mjid_from.fr}}</span>
					</template>
					<template #filter="{filterModel}">
						<div class="p-mb-3 p-text-bold">Major Picker</div>
						<MultiSelect v-model="filterModel.value" :options="majors" optionLabel="name" placeholder="Any"
							class="p-column-filter">
							<template #option="slotProps">
								<div class="p-multiselect-representative-option">
									<span class="image-text">{{slotProps.option.name}}</span>
								</div>
							</template>
						</MultiSelect>
					</template>
				</Column>

				<Column field="status" header="状态" filterField="status" :showFilterMatchModes="false"
					:filterMenuStyle="{'width':'8rem'}" style="min-width:6rem">
					<template #body="{data}">
						<span class="p-column-title">状态</span>
						<span :class="'product-badge status-' + data.status">{{data.status}}</span>
					</template>
					<template #filter="{filterModel}">
						<div class="p-mb-3 p-text-bold">Status Picker</div>
						<Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any"
							class="p-column-filter" :showClear="true">
							<template #value="slotProps">
								<span :class="'product-badge status-' + slotProps.value"
									v-if="slotProps.value">{{slotProps.value}}</span>
								<span v-else>{{slotProps.placeholder}}</span>
							</template>
							<template #option="slotProps">
								<span :class="'product-badge status-' + slotProps.option">{{slotProps.option}}</span>
							</template>
						</Dropdown>
					</template>
				</Column>

				<Column header="更新日期" filterField="date" field="ut" dataType="date" :filterMenuStyle="{'width':'10rem'}"
					style="min-width:8rem">
					<template #body="{data}">
						<span class="p-column-title">日期</span>
						{{formatDate(data.date)}}
					</template>
					<template #filter="{filterModel}">
						<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
						<!-- <Calendar :showIcon="true" :showButtonBar="true" v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy"></Calendar> -->
						<!-- <InputNumber v-model="filterModel.value" placeholder="mm/dd/yyyy" /> -->
						<!-- <InputText type="text" v-model="filterModel.value" id='filter_time' class="p-column-filter" disabled='true' placeholder="yyyy/mm/dd"/> -->
						<!-- <InputText type="text" @input="timeInputBlur" id='input_time' class="p-column-filter" placeholder="yyyy/mm/dd"/> -->
					</template>
				</Column>

				<Column :filterMenuStyle="{'width':'10rem'}" style="min-width:8rem">
					<template #body="slotProps">
						<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
							@click="editProduct(slotProps.data)" />
						<Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
							@click="confirmDeleteProduct(slotProps.data)" />
					</template>
				</Column>
			</DataTable>

			<!-- 删除所选单个项目的提示 -->
			<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="提示" :modal="true">
				<div class="confirmation-content">
					<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem;" />
					<span v-if="product">删除后将无法恢复，仍然确定要删除<b> {{product.or}} </b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
					<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
				</template>
			</Dialog>
			<!-- 删除所选多个项目的提示 -->
			<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="重要提示" :modal="true">
				<div class="confirmation-content">
					<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
					<span v-if="product">删除后将无法恢复，仍然确定要删除吗？</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
					<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
				</template>
			</Dialog>
		</div>
		<!-- </div> -->
	</div>

</template>

<script>
	import {
		FilterMatchMode,
		FilterOperator
	} from 'primevue/api';
	import EditWord from './userComponents/EditWord.vue';
	import Utils from './Utils.vue';
	import pageOperator from './userComponents/pageOperator.vue';
	import WordManagerHelp from '../service/WordManagerHelp.js';
	import NetWorkService from '../service/NetWorkService.js';
	import MajorService from '../service/MajorService.js';
	export default {
		data() {
			return {
				words_result_page_num: 1,
				total_words_result_page_num: 1,

				words_list_status: [{
						'name': '所有单词',
						'button_class': 'info',
						'status': 'all',
						'id': '1'
					},
					{
						'name': '未保存列表',
						'button_class': 'danger',
						'status': 'unsaved',
						'id': '2'
					},
					{
						'name': '已保存列表',
						'button_class': 'success',
						'status': 'saved',
						'id': '3'
					}
				],
				this_status: {
					'name': '未保存列表',
					'status': 'unsaved',
					'button_class': 'danger',
					'id': '2'
				},

				filters1: null,
				statuses: [
					'unsaved', 'saved'
				],

				new_trans: null,
				word_list: [],
				select_translation: null,
				products: null,
				productDialog: false,
				disabled_or: true,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				majors: [],
				major: null,
				second_class: {},
				from_page_name:'',
			}
		},
		wordManagerHelp: null,
		majorService: null,
		components: {
			'EditWord': EditWord,
			'Utils': Utils,
			'pageOperator':pageOperator,
		},
		created() {
			this.networkService = new NetWorkService();
			this.wordManagerHelp = new WordManagerHelp();
			this.majorService = new MajorService();
			this.initFilters1();
		},
		beforeRouterLeave(to, from, next) {
			to.meta.keepAlive = true;
		     next();
		 },
		 beforeRouteEnter (to, from, next) {
		 next(vm => {
			
				 vm.from_page_name=from.name;
			 })
		 },
		mounted() {
			this.getPersonalWord();
			this.majorService.getMajors().then(data => {
				this.majors = data;
				this.major = this.majors[0];
				// console.log('当前专业',data);
			});
			// this.major=this.majors[7];
			// this.second_class=this.major[15];
		},
		methods: {
			goPageBack(){
				this.$router.back();
			},
			//初始化页面参数
			initPage() {
				this.products = null;
				this.selectedProducts = null;
				this.product = {};
			},
			//格式化时间的格式
			formatDate(value) {
				return value.toLocaleDateString('en-US', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				});
			},
			//筛选的结构
			initFilters1() {
				this.filters1 = {
					'global': {
						value: null,
						matchMode: FilterMatchMode.CONTAINS
					},
					'or': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.STARTS_WITH
						}]
					},
					// 'sl.ref': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'sl.ref': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					},
					'sl.scip': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					},
					'sl.w': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.STARTS_WITH
						}]
					},
					'mj': {
						value: null,
						matchMode: FilterMatchMode.IN
					},
					//日期筛选
					'date': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.DATE_IS
						}]
					},
					'sl.sl_mjid_from.fr': {
						operator: FilterOperator.AND,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					},
					'status': {
						operator: FilterOperator.OR,
						constraints: [{
							value: null,
							matchMode: FilterMatchMode.EQUALS
						}]
					}
				}
			},
			initialProduct() {
				this.product = null;
				this.productDialog = false;
				this.disabled_or = true;
				// console.log('当前单词已重置');
			},
			editProduct(product) {
				this.product = {
					...product
				};
				this.product = product;
				this.productDialog = true;
				this.disabled_or = true;
			},
			showNoSelectFail() {
				this.$refs.Utils.showNoSelectWordsFail();
			},
			showUpdateSuccessFully() {
				this.$refs.Utils.showUpdateWordsSuccessFully();
			},
			async uploadWords() {
				// console.log(this.selectedProducts);
				this.$appState.processing=true;
				if (!this.selectedProducts) {
					this.showNoSelectFail();
					return;
				}
				this.processing = true;
			
				var data = new URLSearchParams();
				data.append("upload_words", JSON.stringify(this.selectedProducts));
				var url = '/paper/upload-personal-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.showUpdateSuccessFully();
					this.reflushProduts();
				}
				this.selectedProducts = null;
				this.$appState.processing=false;
			},
			//获取个人词表
			async getPersonalWord() {
				this.processing = true;
				this.$appState.processing=true;
				var data = new URLSearchParams();
				//用结构化查询字典查询单词
				var search_data = this.$refs.pageOperate.getStructSearchData();
				data.append("search_data", JSON.stringify(search_data));
				var url = '/paper/search-personal-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.products = resp.data.personal_words;
				// console.log(this.products);
				this.$refs.pageOperate.total_page_num = resp.data.total_page;
				this.createIdForAllProduts();
				//未保存单词计数
				this.$appState.new_words_num = resp.data.unsaved_count;
				this.processing = false;
				this.$appState.processing=false;
			},
			openNew() {
				var product = {
					"ctn": "",
					"id":this.wordManagerHelp.createId(),
					"or": "",
					"sl": {},
					"status": "unsaved",
					"tl": [],
					"ut": "0000-00-00"
				};
				this.product=product;
				this.submitted = false;
				this.disabled_or = false;
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				this.deleteProductDialog = false;
				if (this.product.orid) {
					this.deleteWords([this.product.orid]);
				} else {
					this.showUpdateSuccessFully();
					this.product = {};
				}
			},
			//删除个人词表
			async deleteWords(orids) {
				// console.log(this.selectedProducts);
				// this.processing = true;
				this.$appState.processing=true;
				var data = new URLSearchParams();
				data.append("orids", JSON.stringify(orids));
				var url = '/paper/delete-personal-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.showUpdateSuccessFully();
				}
				this.$appState.processing=false;
				// this.processing = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createIdForAllProduts() {
				//给所有词表生成本地id
				if (!this.products) {
					return;
				}
				for (var i = 0; i < this.products.length; i++) {
					this.products[i].id = this.wordManagerHelp.createId();
					//新增date属性用于筛选
					this.products[i].date = new Date(this.products[i].ut);
				}
				// console.log(this.products);
			},
			exportCSV() {
				// console.log(this.selectedProducts);
				if (!this.products) {
					this.$refs.Utils.showNoSelectWordsFail();
					return;
				}
				this.joinWordToWL();
				this.$refs.dt.exportCSV(true);
				//删除wl属性
				for(var i=0;i<this.products.length;i++){
					delete this.products[i].wl;
					console.log('删除成功');
				}
			},
			//将所选词表的单词合起来做成wl，便于导出
			joinWordToWL() {
				for (var i = 0; i < this.products.length; i++) {
					var this_product = this.products[i];
					var tl = this_product.tl;
					//该对象的词表
					var wl = tl[0].w+'['+tl[0].sl_mjid_from.fr+']';
					for (var j = 1; j < tl.length; j++) {
						var word = tl[j];
						wl +='; '+word.w+'['+word.sl_mjid_from.fr+']';
					}
					//将构造的词表加入到总表中
					this.products[i]['wl'] = wl;
				}
				// console.log(this.products);
			},
			confirmDeleteSelected() {
				this.deleteProductsDialog = true;
			},
			//延迟2s刷新单词列表
			reflushProduts() {
				this.initPage();
				var that = this;
				setTimeout(function() {
					that.getPersonalWord();
				}, 2000);
			},
			deleteSelectedProducts() {
				this.products = this.products.filter(val => !this.selectedProducts.includes(val));
				this.deleteProductsDialog = false;
				var orids = [];
				for (var i in this.selectedProducts) {
					var orid = this.selectedProducts[i].orid;
					if (orid) {
						orids.push(orid);
					}
				}
				//删除云端对象
				if (orid.length > 0) {
					this.deleteWords(orids)
				} else {
					this.showUpdateSuccessFully();
				}
				this.selectedProducts = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	.translation-item {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.table-header {
		display: flex;
		justify-content: space-between;
	}

	.product-image {
		width: 100px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	.p-dialog .product-image {
		width: 150px;
		margin: 0 auto 2rem auto;
		display: block;
	}

	.confirmation-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-saved {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-unsaved {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	::v-deep(.p-toolbar) {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
	}
</style>
